// extracted by mini-css-extract-plugin
export var aboutContainer = "features_index-module--about-container--34iaD";
export var aboutContent = "features_index-module--about-content--1-PXx";
export var appSlider = "features_index-module--app-slider--BSnfX";
export var contentWrapper = "features_index-module--content-wrapper--1f8Un";
export var defaultContainer = "features_index-module--default-container--3a0aI";
export var featureSlider = "features_index-module--feature-slider---k-yW";
export var features = "features_index-module--features--1cw6M";
export var featuresItem = "features_index-module--features-item--3OL7Q";
export var infoBoxImage = "features_index-module--info-box-image--2Ev26";
export var innerPagesSlider = "features_index-module--inner-pages-slider--1URt0";
export var leftAppSlide = "features_index-module--left-app-slide--MYPfL";
export var logoSlider = "features_index-module--logo-slider--wluh6";
export var numbers = "features_index-module--numbers--3m2mW";
export var phoneAppSelect = "features_index-module--phone-app-select--2ur_l";
export var phoneCaseSelect = "features_index-module--phone-case-select--1ymKz";
export var rightAppSlide = "features_index-module--right-app-slide--1m7-c";
export var slickActive = "features_index-module--slick-active--1nlKe";
export var slickArrow = "features_index-module--slick-arrow--2gbDD";
export var slickCenter = "features_index-module--slick-center--PtwOO";
export var slickDots = "features_index-module--slick-dots--1Ew8B";
export var slickNext = "features_index-module--slick-next--3zFL1";
export var slickPrev = "features_index-module--slick-prev--K6mKt";
export var slickSlide = "features_index-module--slick-slide--2CFu_";
export var slickSlider = "features_index-module--slick-slider--3PYHS";
export var slickTrack = "features_index-module--slick-track--1JQqt";
export var solvingSlider = "features_index-module--solving-slider--3zRAb";
export var title = "features_index-module--title--1dHYk";
export var titleWrapper = "features_index-module--title-wrapper--2Hk65";