// extracted by mini-css-extract-plugin
export var action = "action_square-module--action--218JH";
export var appSlider = "action_square-module--app-slider--1SgZ0";
export var arrowBottom = "action_square-module--arrowBottom--1uP-A";
export var arrowTop = "action_square-module--arrowTop--3wXVe";
export var callContainer = "action_square-module--call-container--1Fu76";
export var contentWrapper = "action_square-module--content-wrapper--tl8CX";
export var defaultContainer = "action_square-module--default-container--YxjGW";
export var description = "action_square-module--description--TMhBV";
export var featureSlider = "action_square-module--feature-slider--1jN15";
export var header = "action_square-module--header--2X5od";
export var imageContent = "action_square-module--image-content--1Hjmi";
export var innerPagesSlider = "action_square-module--inner-pages-slider--X-osW";
export var leftAppSlide = "action_square-module--left-app-slide--vECVp";
export var logoSlider = "action_square-module--logo-slider--18R7T";
export var phoneAppSelect = "action_square-module--phone-app-select--2z9MF";
export var phoneCaseSelect = "action_square-module--phone-case-select--2f4y1";
export var rightAppSlide = "action_square-module--right-app-slide--2A3Y6";
export var slickActive = "action_square-module--slick-active--3NKRY";
export var slickArrow = "action_square-module--slick-arrow--1bqui";
export var slickCenter = "action_square-module--slick-center--1SSCb";
export var slickDots = "action_square-module--slick-dots--KOs0T";
export var slickNext = "action_square-module--slick-next--kiZVM";
export var slickPrev = "action_square-module--slick-prev--1zO2X";
export var slickSlide = "action_square-module--slick-slide--2_Qn4";
export var slickSlider = "action_square-module--slick-slider--1gbrZ";
export var slickTrack = "action_square-module--slick-track--3uKAM";
export var solvingSlider = "action_square-module--solving-slider--3WcQp";