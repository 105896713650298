import { graphql, useStaticQuery } from "gatsby"

export default function TechFoundersData() {
  return useStaticQuery(graphql`
    query queryFounder {
      seo: sanitySeo(slug: { current: { eq: "tech-founders" } }) {
        meta_title
        meta_description
        keywords
        meta_image {
          asset {
            url
          }
        }
        alt_tag
      }
      hero: sanityHero(slug: { current: { eq: "tech-founders" } }) {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      video: sanityVideoSection(slug: { current: { eq: "new-idea" } }) {
        description
        quote
        slug {
          current
        }
        title
        hints {
          parts
        }
        backgroundColor {
          hex
        }
        video_link
        isHintsAnimation
      }
      features: sanityFeatures(
        slug: { current: { eq: "benefits-of-idea-validation" } }
      ) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      about: sanityAbout(slug: { current: { eq: "why-choose-devwiz" } }) {
        description
        title
        hints {
          parts
        }
        backgroundColor {
          hex
        }
        image {
          asset {
            url
          }
        }
        isHintsAnimation
      }
      features2: sanityFeatures(
        slug: { current: { eq: "common-pain-points" } }
      ) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      approach: sanityFeatures(slug: { current: { eq: "devwiz-approach" } }) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          backgroundColor {
            hex
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      testimonial: sanityTestimonials(slug: { current: { eq: "light" } }) {
        title
        testimonials {
          author {
            name
            position
            image {
              asset {
                url
              }
            }
          }
          text
        }
        shadowBackgroundColor {
          hex
        }
        cardBackgroundColor {
          hex
        }
        backgroundColor {
          hex
        }
      }
      action: sanityCallAction(slug: { current: { eq: "strategize" } }) {
        title
        button
        description
        image {
          asset {
            url
          }
        }
        background {
          asset {
            url
          }
        }
        backgroundColor {
          hex
        }
        target
      }
      services: sanityFeatures(
        slug: { current: { eq: "services-for-startups" } }
      ) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      process: sanityCallAction(
        slug: { current: { eq: "kickstart-the-process" } }
      ) {
        title
        button
        image {
          asset {
            url
          }
        }
        description
        background {
          asset {
            url
          }
        }
        backgroundColor {
          hex
        }
        target
      }
    }
  `)
}
