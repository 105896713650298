// extracted by mini-css-extract-plugin
export var aboutContainer = "features_scroll-module--about-container--3HS47";
export var aboutContent = "features_scroll-module--about-content--hQvv_";
export var appSlider = "features_scroll-module--app-slider--uvb_x";
export var contentWrapper = "features_scroll-module--content-wrapper--TYABK";
export var defaultContainer = "features_scroll-module--default-container--2GQq9";
export var featureSlider = "features_scroll-module--feature-slider--O_f-i";
export var features = "features_scroll-module--features--2MJqO";
export var featuresItem = "features_scroll-module--features-item--7SEWQ";
export var innerPagesSlider = "features_scroll-module--inner-pages-slider--3Ep2_";
export var leftAppSlide = "features_scroll-module--left-app-slide--mfnHL";
export var logoSlider = "features_scroll-module--logo-slider--AoY0F";
export var numbers = "features_scroll-module--numbers--1KXl_";
export var phoneAppSelect = "features_scroll-module--phone-app-select--15hdt";
export var phoneCaseSelect = "features_scroll-module--phone-case-select--2KWRK";
export var rightAppSlide = "features_scroll-module--right-app-slide--1dR-t";
export var slickActive = "features_scroll-module--slick-active--1AoCF";
export var slickArrow = "features_scroll-module--slick-arrow--3y5A9";
export var slickCenter = "features_scroll-module--slick-center--3lZdI";
export var slickDots = "features_scroll-module--slick-dots--2ykSk";
export var slickNext = "features_scroll-module--slick-next--3npST";
export var slickPrev = "features_scroll-module--slick-prev--3S44J";
export var slickSlide = "features_scroll-module--slick-slide--3eFXo";
export var slickSlider = "features_scroll-module--slick-slider--2-yvw";
export var slickTrack = "features_scroll-module--slick-track--3kc71";
export var solvingSlider = "features_scroll-module--solving-slider--1i4pQ";
export var title = "features_scroll-module--title--2D6U_";
export var titleWrapper = "features_scroll-module--title-wrapper--3um-9";