import React from "react"
import { replaceSpacing } from "../../utils/functions"
import { animationSlideUp, animationSlideRight, animationSlideLeft, animationFade, animationZoomIn } from "../../utils/scroll-animation"
import Hints from "../../common/hints/hints"

export default function About(props) {
  const { content, styles } = props
  return (
    <section
      style={{backgroundColor: content?.backgroundColor ? content.backgroundColor.hex : ""}}
      className={styles.objective}
    >
      <div className="default-container">
        <div className={styles.aboutContainer}>
          <div className={styles.titleWrapper}>
            <h1 style={{ whiteSpace: "pre-wrap" }} {...animationSlideRight()}>
              {replaceSpacing(content.title)}
            </h1>
            <Hints hints={content?.hints} isAnimation={content?.isHintsAnimation}
              typingBackground={content?.backgroundColor ? content.backgroundColor.hex: "#673895"}
            />
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <div className={styles.history} {...animationSlideUp()}>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {replaceSpacing(content.description)}
                </p>
              </div>
              <div className={styles.image} {...animationZoomIn()}>
                <img src={content.image.asset.url} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
