import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Hero from "../sections/hero/hero"
import ClientExp from "../sections/clients_exp/clients_exp"
import Video from "../sections/video_section/Video"
import Features from "../sections/features/features"
import Services from "../sections/services/services"
import CallToAction from "../sections/call_to_action/call_to_action"
import Layout from "../components/Layout"
import About from "../sections/about/about"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import * as client_exp_styles from "../sections/clients_exp/clients_exp.module.scss"
import * as video_styles from "../sections/video_section/video_index.module.scss"
import * as feature_styles from "../sections/features/features_index.module.scss"
import * as feature_scroll_style from "../sections/features/features_scroll.module.scss"
import * as icon_background from "../sections/features/icon_background.module.scss"
import * as services_styles from "../sections/services/services.module.scss"
import * as square_call from "../sections/call_to_action/action_square.module.scss"
import * as book_call from "../sections/call_to_action/book.module.scss"
import * as about_styles from "../sections/about/about.module.scss"
import TechFoundersData from "../utils/techFounders-queries"
import { getSeo } from "../utils/seo"

export default function TechFounders(props) {
  const {
    hero,
    video,
    features,
    about,
    features2,
    approach,
    testimonial,
    action,
    services,
    process,
  } = TechFoundersData()

  return (
    <Layout navBG={hero?.backgroundColor ? hero.backgroundColor.hex : ""}>
      {/* <Seo
        title={tech_founders.title}
        keywrods={tech_founders.keywrods}
        description={tech_founders.description}
      /> */}
      <Hero
        hero={hero}
        styles={hero_styles}
        nextSection={video.slug?.current}
      />
      <Video content={video} styles={video_styles} />
      <Features features={features} styles={feature_styles} />
      <About content={about} styles={about_styles} />
      <Features features={features2} styles={feature_scroll_style} />
      <Features features={approach} styles={icon_background} />
      <ClientExp content={testimonial} styles={client_exp_styles} />
      <CallToAction call={action} styles={book_call} />
      <Services content={services} styles={services_styles} />
      <CallToAction call={process} styles={square_call} arrow={true} />
    </Layout>
  )
}

export const Head = () => {
  const { seo } = TechFoundersData()
  return getSeo(seo)
}
