// extracted by mini-css-extract-plugin
export var aboutContainer = "about-module--about-container--1hVDN";
export var appSlider = "about-module--app-slider--eACcU";
export var content = "about-module--content--8C9gT";
export var contentWrapper = "about-module--content-wrapper--2civ6";
export var defaultContainer = "about-module--default-container--3Fee7";
export var featureSlider = "about-module--feature-slider--neM8E";
export var history = "about-module--history--3dN6T";
export var image = "about-module--image--29aiB";
export var innerPagesSlider = "about-module--inner-pages-slider--1WocW";
export var leftAppSlide = "about-module--left-app-slide--2vdfs";
export var logoSlider = "about-module--logo-slider--29JPv";
export var objective = "about-module--objective--os3Hi";
export var phoneAppSelect = "about-module--phone-app-select--1muez";
export var phoneCaseSelect = "about-module--phone-case-select--IzL_B";
export var quotes = "about-module--quotes--24r2w";
export var rightAppSlide = "about-module--right-app-slide--39miB";
export var slickActive = "about-module--slick-active--gIkMo";
export var slickArrow = "about-module--slick-arrow--3aRLo";
export var slickCenter = "about-module--slick-center--26Unc";
export var slickDots = "about-module--slick-dots--26Xpf";
export var slickNext = "about-module--slick-next--d_rF1";
export var slickPrev = "about-module--slick-prev--3NCRs";
export var slickSlide = "about-module--slick-slide--1ElUI";
export var slickSlider = "about-module--slick-slider--2PyPH";
export var slickTrack = "about-module--slick-track--16gF-";
export var solvingSlider = "about-module--solving-slider--1vPbp";
export var titleContainer = "about-module--titleContainer--1_5Y3";
export var titleWrapper = "about-module--title-wrapper--zRFgg";